export const getAuthenticityToken = () => {
  var token = document.querySelector('meta[name="csrf-token"]');
  if (token && (token instanceof window.HTMLMetaElement)) {
      return token.content;
  }
  return null;
}


export const Authenticity = {
  headers() {
    return {
      "X-CSRF-Param": document.head.querySelector("[name=csrf-param]").content,
      "X-CSRF-Token": getAuthenticityToken(),
    };
  },
};
